import {
    useClick,
    useDismiss,
    useFloating,
    useInteractions
} from '@floating-ui/react';
import { useMutation } from '@tanstack/react-query';
import { CircleAlert } from 'lucide-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { Button } from 'sam-ui-kit';

import { notificateErrorResponse } from 'utils/serverErrors';

import { CardValue } from 'components/CardValue/CardValue';
import { Divider } from 'components/Divider/Divider';
import { FormAutocomplete } from 'components/Form/FormAutocomplete';
import { FormSegmentControlField } from 'components/Form/FormSegmentControlField';
import { FormTextareaField } from 'components/Form/FormTextareaField';
import { StatusText } from 'components/StatusText/StatusText';

import { REJECTED } from 'modules/Applications/consts';
import { WagonAcceptanceInSituationDetails } from 'modules/Situations/components/WagonAcceptanceInSituationDetails/WagonAcceptanceInSituationDetails';
import { useWagonFieldRule } from 'modules/Situations/hooks/useWagonFieldRule';
import { detachSituationWagons } from 'modules/Situations/services';

import styles from './WagonCard.module.scss';

export const WagonCard = ({
    index,
    wagon,
    situationId,
    refetch,
    situationAccepted,
    isDisabled
}) => {
    const { t } = useTranslation();

    const wagonAcceptanceInSituationRule = useWagonFieldRule(
        'wagonAcceptanceInSituation',
        index
    );
    const wagonAcceptanceInSituationDefectRule = useWagonFieldRule(
        'wagonAcceptanceInSituationDefect',
        index
    );

    const [showAdditionalInfo, setShowAdditionalInfo] = useState(false);

    const { refs, context } = useFloating({
        open: showAdditionalInfo,
        onOpenChange: setShowAdditionalInfo
    });

    const { getReferenceProps, getFloatingProps } = useInteractions([
        useDismiss(context),
        useClick(context)
    ]);

    const detachMutation = useMutation({
        mutationFn: () =>
            detachSituationWagons({ situationId, wagonId: wagon.id }),
        onSuccess: refetch,
        onError: (response) => notificateErrorResponse(response, t)
    });

    const renderStatus = () => {
        const status = wagon.initialWagonAcceptanceStatus;
        return status ? (
            <StatusText
                label={t(`applications:status_cargo.${status}`)}
                variant={status === REJECTED ? 'red' : 'green'}
                textAlign="right"
                noBackground={true}
            />
        ) : (
            ''
        );
    };

    const renderLastSituationStatus = () => {
        const status = wagon.wagonAcceptanceStatusInLastSituation;
        if (status === true) {
            return (
                <StatusText
                    label={t('statuses.accepted')}
                    variant="green"
                    textAlign="right"
                    noBackground={true}
                />
            );
        }
        if (status === false) {
            return (
                <StatusText
                    label={t('statuses.not_accepted')}
                    variant="red"
                    textAlign="right"
                    noBackground={true}
                />
            );
        }
        return '';
    };

    const renderDetachInfo = () => {
        if (!wagon.detached && wagon.detachable) {
            return (
                <div>
                    <Divider />
                    <Button
                        label={t('situations:remove_protection')}
                        block={true}
                        onClick={detachMutation.mutate}
                        disabled={detachMutation.isPending}
                        variant="default"
                        size="lg"
                    />
                </div>
            );
        }
        if (wagon.detached) {
            return (
                <>
                    <Divider />
                    <div className={styles.detached}>
                        {t('situations:removed_under_protection')}
                    </div>
                </>
            );
        }
    };

    const renderAdditionalInfo = () => {
        return (
            <div
                className={styles.panel}
                ref={refs.setFloating}
                {...getFloatingProps()}
            >
                <div className={styles.panelItem}>
                    <div>{t('wagonType')}:</div>
                    <div>{wagon.wagonType}</div>
                </div>
                {wagon.appId && (
                    <div className={styles.panelItem}>
                        <div>{t('applications:application')}: </div>
                        <div>
                            <NavLink
                                to={`/applications/reception/${wagon.appId}`}
                                className={styles.panelLink}
                            >
                                {wagon.appId}
                            </NavLink>
                        </div>
                    </div>
                )}
            </div>
        );
    };

    return (
        <div className={styles.wagonCard} id={wagon.wagonCode}>
            <div className={styles.header}>
                <div className={styles.title}>
                    {wagon.wagonOrder}.
                    {wagon.appWagonId ? (
                        <NavLink
                            to={`/situations/${situationId}/wagon/${wagon.appWagonId}`}
                            className={styles.wagonLink}
                        >
                            {wagon.wagonCode}
                        </NavLink>
                    ) : (
                        <span className={styles.wagon}>{wagon.wagonCode}</span>
                    )}
                </div>
                <div className={styles.subtitle}>
                    {wagon.cargoCode
                        ? `${wagon.cargoCode.code}-${wagon.cargoCode.name}`
                        : ''}
                </div>
                <div className={styles.info}>
                    <span ref={refs.setReference} {...getReferenceProps()}>
                        <CircleAlert
                            color="#4079e4"
                            className={styles.pointer}
                        />
                    </span>
                    {showAdditionalInfo && renderAdditionalInfo()}
                </div>
            </div>
            <Divider classNames={styles.divider} />

            <div className={styles.info}>
                <CardValue
                    label={t('situations:initial_acceptance_short')}
                    value={renderStatus()}
                />
                <CardValue
                    label={t('situations:defect')}
                    value={wagon.initialWagonAcceptanceStatusDefect?.name}
                />
                <CardValue
                    label={t('situations:note')}
                    value={
                        wagon.initialWagonAcceptanceStatusText && (
                            <span
                                className={styles.note}
                                title={wagon.initialWagonAcceptanceStatusText}
                            >
                                {wagon.initialWagonAcceptanceStatusText}
                            </span>
                        )
                    }
                />

                <CardValue
                    label={t('situations:attributesExistence')}
                    value={
                        wagon.attributesExistence === true
                            ? t('actions.yes')
                            : t('actions.no')
                    }
                />

                <Divider classNames={styles.divider} />

                <CardValue
                    label={t('situations:prev_situation_short')}
                    value={renderLastSituationStatus()}
                />
                <CardValue
                    label={t('situations:defect')}
                    value={
                        wagon.wagonAcceptanceStatusInLastSituationDefect?.name
                    }
                />
                <CardValue
                    label={t('situations:note')}
                    value={
                        <span
                            className={styles.note}
                            title={
                                wagon.wagonAcceptanceStatusInLastSituationText
                            }
                        >
                            {wagon.wagonAcceptanceStatusInLastSituationText}
                        </span>
                    }
                />

                {situationAccepted &&
                    wagon.wagonAcceptanceInSituation !== undefined && (
                        <div>
                            <Divider />
                            <WagonAcceptanceInSituationDetails wagon={wagon} />
                        </div>
                    )}
            </div>

            {!isDisabled &&
                (wagon.acceptable ? (
                    <div>
                        <Divider />
                        <div className={styles.controls}>
                            <FormSegmentControlField
                                name={`wagons.${index}.wagonAcceptanceInSituation`}
                                segmentsConfig={[
                                    {
                                        label: t('actions.accepting'),
                                        value: 'true'
                                    },
                                    {
                                        label: t('actions.not_accepting'),
                                        value: 'false'
                                    }
                                ]}
                                variant="button"
                                rules={wagonAcceptanceInSituationRule}
                                disableHint
                                noHintSpace
                            />
                            <FormAutocomplete
                                clearable
                                name={`wagons.${index}.wagonAcceptanceInSituationDefect`}
                                catalogName="WAGON_DEFECTS"
                                rules={wagonAcceptanceInSituationDefectRule}
                                useFullModel
                                disableHint
                                noHintSpace
                            />
                            <FormTextareaField
                                name={`wagons.${index}.wagonAcceptanceInSituationText`}
                                rows={2}
                                placeholder={t(
                                    'situations:enter_note_if_exists'
                                )}
                                noHintSpace={true}
                            />
                        </div>
                    </div>
                ) : (
                    renderDetachInfo()
                ))}
        </div>
    );
};
