import { useQuery } from '@tanstack/react-query';

import { getFile } from 'core/services/file';

import { Spinner } from 'components/Spinner/Spinner';

export const Image = ({ id, className, alt, placeHolder }) => {
    const imageQuery = useQuery({
        queryKey: ['image', id],
        queryFn: () => getFile(id),
        enabled: !!id
    });

    if (imageQuery.isFetched && imageQuery.status === 'success') {
        return (
            <img
                className={className}
                src={URL.createObjectURL(imageQuery.data.data)}
                alt={alt}
            />
        );
    } else if (imageQuery.isLoading) {
        return <Spinner />;
    }

    return placeHolder ? placeHolder : <div />;
};
