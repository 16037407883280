import styles from './Spinner.module.scss';

export const Spinner = () => {
    return (
        <svg
            className={styles.iconSpin}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect x="11" width="2" height="6" rx="1" fill="#9F9DA5" />
            <rect x="11" y="18" width="2" height="6" rx="1" fill="#9F9DA5" />
            <rect
                y="13"
                width="2"
                height="6"
                rx="1"
                transform="rotate(-90 0 13)"
                fill="#9F9DA5"
            />
            <rect
                x="18"
                y="13"
                width="2"
                height="6"
                rx="1"
                transform="rotate(-90 18 13)"
                fill="#9F9DA5"
            />
            <rect
                x="2.80762"
                y="4.2218"
                width="2"
                height="6"
                rx="1"
                transform="rotate(-45 2.80762 4.2218)"
                fill="#9F9DA5"
            />
            <rect
                x="15.5352"
                y="16.9497"
                width="2"
                height="6"
                rx="1"
                transform="rotate(-45 15.5352 16.9497)"
                fill="#9F9DA5"
            />
            <rect
                x="4.22168"
                y="21.1924"
                width="2"
                height="6"
                rx="1"
                transform="rotate(-135 4.22168 21.1924)"
                fill="#9F9DA5"
            />
            <rect
                x="16.9492"
                y="8.46436"
                width="2"
                height="6"
                rx="1"
                transform="rotate(-135 16.9492 8.46436)"
                fill="#9F9DA5"
            />
        </svg>
    );
};
