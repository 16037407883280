import { noop } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Table } from 'sam-ui-kit';
import { showModal } from 'src/core/modal';
import { formatDate } from 'src/utils/dateUtils';

import { useDataRequest } from 'core/queries/useDataRequest';

import { useCombineFilters } from 'hooks/useCombineFilters';
import { usePagination } from 'hooks/usePagination';
import { useSort } from 'hooks/useSort';

import { TableRowEditControls } from 'components/TableRowEditControls/TableRowEditControls';

import { DefectStatus } from 'modules/Defects/components/DefectStatus';
import { DefectsEditModal } from 'modules/Defects/components/DefectsEditModal/DefectsEditModal';
import { DEFECTS_PAGE_SIZE, defectInitialSort } from 'modules/Defects/consts';
import { getDefectPagedList } from 'modules/Defects/services';

import styles from './DefectsTable.module.scss';

export const DefectsTable = ({ customFilters }) => {
    const { t } = useTranslation();

    const combinedFilters = useCombineFilters({
        filters: {
            sort: useSort(defectInitialSort),
            pagination: usePagination(
                { pageIndex: 1, pageSize: DEFECTS_PAGE_SIZE },
                true
            )
        },
        listeners: { customFilters },
        resetPagination: { onListenersChange: true }
    });

    const { data = {}, isFetching } = useDataRequest(
        'getDefectPagedList',
        getDefectPagedList,
        combinedFilters.filters
    );

    const handleEdit = (id) => {
        showModal(DefectsEditModal, { id }).then(noop, noop);
    };

    return (
        <div className={styles.table}>
            <Table
                loading={isFetching}
                data={data.content ?? []}
                columns={getColumnsConfig(t, handleEdit)}
                pageSize={DEFECTS_PAGE_SIZE}
                pageCount={data?.totalPages}
                pagination={combinedFilters.filters.pagination}
                onPaginationChange={combinedFilters.setPagination}
                sorting={combinedFilters.filters.sort}
                onSortingChange={combinedFilters.setSort}
            />
        </div>
    );
};

const getColumnsConfig = (t, onEdit) => {
    return [
        {
            header: t('defects:defect_created_station'),
            accessorKey: 'station',
            cell: ({ row }) => row.original.station?.name,
            enableSorting: true
        },
        {
            header: t('defects:detached_station'),
            accessorKey: 'detachedStation',
            cell: ({ row }) => row.original.detachedStation?.name,
            enableSorting: true
        },
        {
            header: t('defects:park'),
            accessorKey: 'park',
            cell: ({ row }) => row.original.park?.nameRu,
            enableSorting: true
        },
        {
            header: t('date'),
            accessorKey: 'createdAt',
            cell: ({ row }) => formatDate(row.original.createdAt),
            enableSorting: true,
            size: 200
        },
        {
            header: t('defects:security'),
            accessorKey: 'guard',
            cell: ({ row }) =>
                `${row.original.guard?.firstName} ${row.original.guard?.lastName}`,
            enableSorting: true
        },
        {
            header: t('defects:carriage'),
            accessorKey: 'wagonCode',
            enableSorting: true
        },
        {
            header: t('defects:comment'),
            accessorKey: 'defect',
            cell: ({ row }) => row.original.defect?.nameRu,
            enableSorting: true
        },
        {
            header: t('description'),
            accessorKey: 'note',
            cell: ({ row }) => row.original.note,
            enableSorting: true
        },
        {
            header: t('status'),
            accessorKey: 'status',
            cell: ({ row }) => <DefectStatus status={row.original.status} />,
            enableSorting: true
        },
        {
            header: '№',
            accessorKey: 'docNumber',
            enableSorting: true
        },
        {
            header: '',
            id: 'actions',
            cell: ({ row }) => {
                return (
                    <TableRowEditControls
                        onEdit={() => onEdit(row.original.id)}
                    />
                );
            },
            size: 30,
            minSize: 30
        }
    ];
};
