import { noop } from 'lodash';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from 'sam-ui-kit';

import { showModal } from 'core/modal';
import { useDataRequest } from 'core/queries/useDataRequest';

import { useCombineFilters } from 'hooks/useCombineFilters';
import { usePagination } from 'hooks/usePagination';
import { useSort } from 'hooks/useSort';

import { formatDate } from 'utils/dateUtils';
import { getFIOShort } from 'utils/textUtils';

import { SkeletonLoader } from 'components/SkeletonLoader/SkeletonLoader';
import { TextButton } from 'components/TextButton/TextButton';
import { TextLink } from 'components/TextLink/TextLink';

import { SituationEditModal } from 'modules/Situations/components/SituationEditModal/SituationEditModal';
import { SITUATION_PAGE_SIZE } from 'modules/Situations/components/consts';
import { useCargoFilterParams } from 'modules/Situations/hooks/useCargoFilterParams';
import { getCargoOncomingList } from 'modules/Situations/services';

import styles from './CargoOncomingList.module.scss';

export const CargoOncomingList = () => {
    const { t } = useTranslation();

    const [customFilters] = useCargoFilterParams();

    const cargoOncomingFilters = useMemo(() => {
        if (customFilters?.arrival_station_code_eq) {
            return {
                station_code_eq: customFilters?.arrival_station_code_eq
            };
        } else {
            return {};
        }
    }, [customFilters]);

    const combinedFilters = useCombineFilters({
        filters: {
            sort: useSort([{ id: 'departureDate', desc: true }]),
            pagination: usePagination(
                { pageIndex: 1, pageSize: SITUATION_PAGE_SIZE },
                true,
                'oncoming'
            )
        },
        listeners: { customFilters: cargoOncomingFilters },
        resetPagination: { onListenersChange: true }
    });

    const { data, isLoading } = useDataRequest(
        'cargoOncomingList',
        getCargoOncomingList,
        combinedFilters.filters
    );

    return isLoading ? (
        <SkeletonLoader width="100%" height={154}>
            <rect width="100%" height={140} rx={15} ry={15} y={14} />
        </SkeletonLoader>
    ) : (
        <div className={styles.situationsOncoming}>
            <h1 className={styles.header}>
                {t('situations:oncoming')}
                <span className={styles.count}>
                    ({data.content?.length ?? t('no_rows')})
                </span>
            </h1>
            {data.content?.length > 0 && (
                <Table
                    data={data.content || []}
                    columns={getColumnsConfig(t)}
                    pageSize={SITUATION_PAGE_SIZE}
                    pageCount={data?.totalPages}
                    sorting={combinedFilters.filters.sort}
                    pagination={combinedFilters.filters.pagination}
                    onPaginationChange={combinedFilters.setPagination}
                    onSortingChange={combinedFilters.setSort}
                />
            )}
        </div>
    );
};

function getColumnsConfig(t) {
    return [
        {
            header: t('situations:index'),
            accessorKey: 'indexPoezd',
            minSize: 200,
            size: 200
        },
        {
            header: t('situations:train_num'),
            accessorKey: 'nomPoezd',
            cell: ({ row }) => (
                <TextLink
                    label={row.original.nomPoezd}
                    url={`/situations/${row.original.id}`}
                />
            ),
            enableSorting: true,
            size: 130,
            minSize: 130
        },
        {
            header: t('situations:security_person'),
            accessorKey: 'escortGuard',
            cell: ({ row }) => {
                if (row.original.escortGuard) {
                    return getFIOShort(row.original.escortGuard, {
                        f: 'lastName',
                        i: 'firstName',
                        o: 'secondName'
                    });
                }
                return '';
            },
            enableSorting: true,
            minSize: 150,
            size: 150
        },
        {
            header: t('from'),
            accessorKey: 'arrivalStation',
            cell: ({ row }) => {
                if (row.original.arrivalStation) {
                    return row.original.arrivalStation.name;
                }
                return '';
            },
            enableSorting: true,
            size: 150,
            minSize: 150
        },
        {
            header: t('situations:departure'),
            accessorKey: 'departureDate',
            cell: ({ row }) => formatDate(row.original.departureDate),
            minSize: 100,
            size: 200,
            enableSorting: true
        },
        {
            header: t('situations:security'),
            accessorKey: 'guardedWagonsCount',
            enableSorting: true,
            size: 75,
            minSize: 75
        },
        {
            header: '',
            id: 'actions',
            cell: ({ row }) => {
                return (
                    <TextButton
                        label="+ Добавить ситуацию"
                        color="green"
                        onClick={() =>
                            showModal(SituationEditModal, {
                                prevSituationId: row.original.id
                            }).then(noop, noop)
                        }
                    />
                );
            },
            size: 250,
            minSize: 250
        }
    ];
}
