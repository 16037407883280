import { replace } from 'lodash';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    DateIntervalPicker,
    FieldControl,
    Input,
    MaskedInput
} from 'sam-ui-kit';
import { useQueryParam, withDefault } from 'use-query-params';

import { CustomFilterParam } from 'core/CustomFilterParam';

import { useDebounce } from 'hooks/useDebounce';

import { Header } from 'components/Header/Header';

import styles from './HistoryHeader.module.scss';

const filterParam = withDefault(CustomFilterParam, {});

export const HistoryHeader = ({ isLoading }) => {
    const { t } = useTranslation();

    const [customFilters, setCustomFilters] = useQueryParam(
        'customFilters',
        filterParam
    );

    const [, setPageQueryParam] = useQueryParam('page');

    const handleChangeCustomFilters = (fn) => {
        setPageQueryParam(1);
        setCustomFilters(fn);
    };

    const debounceValue = useDebounce(({ name, value }) => {
        handleChangeCustomFilters((prevState) => ({
            ...prevState,
            [name]: value || undefined
        }));
    });

    const [container, setContainer] = useState(
        customFilters?.container_code_like || ''
    );

    const [wagon, setWagon] = useState(customFilters?.wagon_code_eq || '');

    const handleInputChange = (event, changeState) => {
        const { name, value } = event?.target;
        changeState(value);
        const valueWithoutMask = replace(value || '', /_/g, '');
        if (
            name === 'wagon_code_eq' &&
            (valueWithoutMask.length === 8 || valueWithoutMask.length === 0)
        ) {
            debounceValue({ name, value: valueWithoutMask });
        } else if (name === 'container_code_like') {
            debounceValue({ name, value });
        }
    };

    return (
        <div className={styles.header}>
            <Header
                title={t('history:app_wagon_history')}
                actions={
                    <div className={styles.actions}>
                        <FieldControl noHintSpace={true}>
                            <MaskedInput
                                name="wagon_code_eq"
                                placeholder={t('history:carriage_number')}
                                value={wagon}
                                onChange={(e) => handleInputChange(e, setWagon)}
                                mask="99999999"
                                error={
                                    replace(wagon || '', /_/g, '').length !==
                                        8 && wagon.length !== 0
                                }
                            />
                        </FieldControl>

                        <FieldControl noHintSpace={true}>
                            <Input
                                name="container_code_like"
                                placeholder={t('history:container')}
                                value={container}
                                onChange={(e) =>
                                    handleInputChange(e, setContainer)
                                }
                            />
                        </FieldControl>

                        <FieldControl noHintSpace={true}>
                            <DateIntervalPicker
                                disabled={isLoading}
                                startDate={customFilters?.dt_gte}
                                endDate={customFilters?.dt_lte}
                                onStartDateChange={(value) =>
                                    handleChangeCustomFilters((prevState) => ({
                                        ...prevState,
                                        dt_gte: value
                                    }))
                                }
                                onEndDateChange={(value) =>
                                    handleChangeCustomFilters((prevState) => ({
                                        ...prevState,
                                        dt_lte: value
                                    }))
                                }
                                onClear={() =>
                                    handleChangeCustomFilters((prevState) => ({
                                        ...prevState,
                                        dt_gte: null,
                                        dt_lte: null
                                    }))
                                }
                            />
                        </FieldControl>
                    </div>
                }
            />
        </div>
    );
};
