import { useContext } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FieldControl, Input } from 'sam-ui-kit';

import { FormStatusContext } from 'components/Form/Form';

export const FormInputField = ({
    rules,
    disabled,
    noHintSpace,
    autoComplete,
    ...otherProps
}) => {
    const methods = useFormContext();
    const formStatus = useContext(FormStatusContext);
    const { t } = useTranslation();

    const { field, fieldState } = useController({
        name: otherProps.name,
        control: methods.control,
        rules
    });

    return (
        <FieldControl
            label={otherProps.label}
            hint={
                (fieldState.error && t(fieldState.error?.message)) ||
                otherProps.hint
            }
            required={rules?.required?.value}
            error={fieldState.invalid}
            noHintSpace={noHintSpace}
        >
            <Input
                {...otherProps}
                disabled={disabled || formStatus.disabled}
                value={field.value}
                onChange={field.onChange}
                onClear={otherProps.isClearable && field.onChange}
                error={fieldState.invalid}
                autoComplete={autoComplete ?? 'off'}
            />
        </FieldControl>
    );
};
