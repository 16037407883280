import { Check, EllipsisVertical, Pencil, Trash2, X } from 'lucide-react';
import { Dropdown, Space } from 'sam-ui-kit';

export const TableRowEditControls = ({
    edit,
    onCancel,
    onDelete,
    onEdit,
    onSelect,
    selectItems
}) => {
    if (edit) {
        return [
            <button className="reset-button" type="submit" key="reset">
                <Check
                    size={16}
                    color="#1A1A1A"
                    cursor="pointer"
                    style={{ display: 'block' }}
                />
            </button>,
            <X
                size={16}
                color="#EC5454"
                cursor="pointer"
                onClick={onCancel}
                style={{ display: 'block' }}
                key="cancel"
            />
        ];
    }

    const controls = [];

    if (onEdit) {
        controls.push(
            <Pencil
                size={16}
                color="#4079E4"
                cursor="pointer"
                onClick={onEdit}
                key="edit"
            />
        );
    }

    if (onDelete) {
        controls.push(
            <Trash2
                size={16}
                color="#C85858"
                cursor="pointer"
                onClick={onDelete}
                key="delete"
            />
        );
    }

    if (onSelect && selectItems) {
        controls.push(
            <Dropdown
                renderElement={() => (
                    <EllipsisVertical
                        size={16}
                        cursor="pointer"
                        color="#4079E4"
                    />
                )}
                listOffset={20}
                options={selectItems}
                onChange={onSelect}
                key="select"
            />
        );
    }

    return <Space gap={12}>{controls}</Space>;
};
