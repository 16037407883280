import api from 'core/api';
import {
    DataRequestDetailsNormalizer,
    DataRequestPageListNormalizer,
    DefaultNormalizer
} from 'core/normalizers/DataRequest';

export const updateDefect = (id, data) => {
    return api.put(`/cargo-defect/api/cargos/defects/${id}`, data);
};

export const getDefectList = (params) => {
    return api.get('/qorgau-data/api/data-request/non-paged/cargo_defects', {
        params,
        normalizer: DataRequestDetailsNormalizer
    });
};

export const getDefectPagedList = (params) => {
    return api.get('/qorgau-data/api/data-request/cargo_defects', {
        params,
        normalizer: DataRequestPageListNormalizer
    });
};

export const getDefectDetails = (id) => {
    return api.get(`/cargo-defect/api/cargos/defects/${id}`, {
        normalizer: DefaultNormalizer
    });
};
