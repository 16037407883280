import { noop } from 'lodash';
import { Filter, PlusCircle } from 'lucide-react';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'sam-ui-kit';
import { showModal } from 'src/core/modal';
import { useQueryParam } from 'use-query-params';

import { queryClient } from 'core/queryClient';

import { Header } from 'components/Header/Header';

import { createApplication } from 'modules/Applications/services/services';

import { ApplicationsEditModal } from '../ApplicationsEditModal/ApplicationsEditModal';
import { ApplicationsFiltersModal } from '../ApplicationsFiltersModal/ApplicationsFiltersModal';
import styles from './ApplicationHeader.module.scss';

export const ApplicationsHeader = () => {
    const { t } = useTranslation();
    const ref = useRef(null);
    const [, setPageQueryParam] = useQueryParam('page');

    const refetchApplications = () => {
        setPageQueryParam(1);
        queryClient.invalidateQueries({
            queryKey: ['getCargosList', 'getCargosListPaged']
        });
    };

    const handleCreate = () => {
        showModal(ApplicationsEditModal, {
            onCreate: createApplication
        }).then(refetchApplications, noop);
    };

    const handleFiltersClick = () => {
        showModal(ApplicationsFiltersModal).then(noop, noop);
    };

    return (
        <div className={styles.header} ref={ref}>
            <Header
                title={t('applications')}
                actions={
                    <div className={styles.actions}>
                        <Button
                            label={t('actions.filter')}
                            variant="default"
                            size="md"
                            icon={<Filter size={16} />}
                            onClick={handleFiltersClick}
                        />
                        <Button
                            label={t('actions.create')}
                            onClick={handleCreate}
                            variant="primary"
                            icon={<PlusCircle size={16} />}
                        />
                    </div>
                }
            />
        </div>
    );
};
