import { useScreenSize } from 'hooks/useScreenSize';

import { DefectCardsList } from 'modules/Defects/components/DefectCardsList/DefectCardsList';
import { DefectsHeader } from 'modules/Defects/components/DefectsHeader/DefectsHeader';
import { DefectsTable } from 'modules/Defects/components/DefectsTable/DefectsTable';

import { useDefectsFilterParams } from '../hooks/useDefectsFilterParams';

export const DefectsPage = () => {
    const { isMobile } = useScreenSize();

    const [customFilters] = useDefectsFilterParams();

    return (
        <>
            <DefectsHeader />
            {isMobile ? (
                <DefectCardsList customFilters={customFilters} />
            ) : (
                <DefectsTable customFilters={customFilters} />
            )}
        </>
    );
};
