import { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from 'sam-ui-kit';

import { useDataRequest } from 'core/queries/useDataRequest';

import { useCombineFilters } from 'hooks/useCombineFilters';
import { usePagination } from 'hooks/usePagination';
import { useSort } from 'hooks/useSort';

import { formatDate } from 'utils/dateUtils';
import { getFIOShort } from 'utils/textUtils';

import { SkeletonLoader } from 'components/SkeletonLoader/SkeletonLoader';
import { TextLink } from 'components/TextLink/TextLink';

import { SITUATION_PAGE_SIZE } from 'modules/Situations/components/consts';
import { useCargoFilterParams } from 'modules/Situations/hooks/useCargoFilterParams';
import { getCargoEscortList } from 'modules/Situations/services';

import styles from './CargoEscortList.module.scss';

export const CargoEscortList = () => {
    const ref = useRef(null);
    const { t } = useTranslation();

    const [customFilters] = useCargoFilterParams();

    const cargoEscortFilters = useMemo(() => {
        if (customFilters?.arrival_station_code_eq) {
            return {
                arrival_station_code_eq: customFilters?.arrival_station_code_eq
            };
        } else {
            return {};
        }
    }, [customFilters]);

    const combinedFilters = useCombineFilters({
        filters: {
            sort: useSort([]),
            pagination: usePagination(
                { pageIndex: 1, pageSize: SITUATION_PAGE_SIZE },
                true,
                'escort'
            )
        },
        listeners: { customFilters: cargoEscortFilters },
        resetPagination: { onListenersChange: true }
    });

    const { data, isLoading } = useDataRequest(
        'cargoEscortList',
        getCargoEscortList,
        combinedFilters.filters
    );

    return isLoading ? (
        <SkeletonLoader width="100%" height={154}>
            <rect width="100%" height={140} rx={15} ry={15} y={14} />
        </SkeletonLoader>
    ) : (
        <div className={styles.situationsEscort} ref={ref}>
            <h1 className={styles.header}>
                {t('situations:escort')}
                <span className={styles.count}>
                    ({data.content?.length ?? t('no_rows')})
                </span>
            </h1>
            {data.content?.length > 0 && (
                <Table
                    pageSize={SITUATION_PAGE_SIZE}
                    data={data.content || []}
                    columns={getColumnsConfig(t)}
                    pageCount={data?.totalPages}
                    sorting={combinedFilters.filters.sort}
                    pagination={combinedFilters.filters.pagination}
                    onPaginationChange={combinedFilters.setPagination}
                    onSortingChange={combinedFilters.setSort}
                />
            )}
        </div>
    );
};

function getColumnsConfig(t) {
    return [
        {
            header: t('situations:index'),
            accessorKey: 'indexPoezd',
            minSize: 200,
            size: 200
        },
        {
            header: t('situations:train_num'),
            accessorKey: 'nomPoezd',
            cell: ({ row }) => (
                <TextLink
                    label={row.original.nomPoezd}
                    url={`/situations/${row.original.id}`}
                />
            ),
            enableSorting: true,
            size: 130,
            minSize: 130
        },
        {
            header: t('situations:security_person'),
            accessorKey: 'escortGuard',
            cell: ({ row }) => {
                if (row.original.escortGuard) {
                    return getFIOShort(row.original.escortGuard, {
                        f: 'lastName',
                        i: 'firstName',
                        o: 'secondName'
                    });
                }
                return '';
            },
            minSize: 150,
            size: 150,
            enableSorting: true
        },
        {
            header: t('to'),
            accessorKey: 'departureStation',
            cell: ({ row }) => {
                if (row.original.departureStation) {
                    return row.original.departureStation.name;
                }
                return '';
            },
            enableSorting: true,
            size: 150,
            minSize: 150
        },
        {
            header: t('situations:departure'),
            accessorKey: 'departureDate',
            cell: ({ row }) => formatDate(row.original.departureDate),
            minSize: 100,
            size: 200,
            enableSorting: true
        },
        {
            header: t('situations:arrival'),
            accessorKey: 'arrivalDate',
            cell: ({ row }) => formatDate(row.original.arrivalDate),
            minSize: 100,
            size: 200,
            enableSorting: true
        },
        {
            header: t('situations:security'),
            accessorKey: 'guardedWagonsCount',
            enableSorting: true,
            size: 75,
            minSize: 75
        },
        {
            header: t('status'),
            accessorKey: 'escortArrived',
            cell: ({ row }) =>
                row.original.escortArrived
                    ? t('situations:arrived')
                    : t('situations:escort'),
            minSize: 150,
            size: 150,
            enableSorting: true
        }
    ];
}
