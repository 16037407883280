import { subDays } from 'date-fns';
import { SYSTEM_DATE_FORMAT } from 'settings';
import { DATA_REQUEST_URL } from 'src/settings';

import api from 'core/api';
import {
    DataRequestDetailsNormalizer,
    DataRequestPageListNormalizer,
    DefaultNormalizer
} from 'core/normalizers/DataRequest';

import { formatDate } from 'utils/dateUtils';

export const loadWagonInfoFromFile = (file) => {
    const request = new FormData();
    request.append('file', file, file.name);
    return api.post(
        '/cargo-asoup/api/cargo-asoup/parse-natural-sheet',
        request,
        {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            normalizer: DefaultNormalizer
        }
    );
};

export const createSituation = (data) => {
    return api.post('/cargo-transfer/api/cargo-transfers/situations', data);
};

export const editSituation = (data) => {
    return api.put(
        `/cargo-transfer/api/cargo-transfers/situations/${data.id}`,
        data
    );
};

export const updateSituation = (id, data) => {
    return api.patch(
        `/cargo-transfer/api/cargo-transfers/situations/${id}`,
        data
    );
};

export const deleteSituation = (id) => {
    return api.delete(`/cargo-transfer/api/cargo-transfers/situations/${id}`);
};

export const getSituationsList = (params) => {
    return api.get('/qorgau-data/api/data-request/non-paged/cargo_situations', {
        params: {
            ...params,
            accepted_date_gte: formatDate(
                subDays(new Date(), 1),
                SYSTEM_DATE_FORMAT
            )
        },
        // params,
        normalizer: DataRequestDetailsNormalizer
    });
};

export const getParkTypesList = (params) => {
    return api.get('/qorgau-mdm/api/data-request/park_types_ui', {
        params,
        normalizer: DataRequestDetailsNormalizer
    });
};

export const getCargoEscortList = (params) => {
    return api.get('/qorgau-data/api/data-request/cargo_escort', {
        params,
        normalizer: DataRequestPageListNormalizer
    });
};

export const getCargoOncomingList = (params) => {
    return api.get('/qorgau-data/api/data-request/cargo_oncoming', {
        params,
        normalizer: DataRequestPageListNormalizer
    });
};

export const getSituationDetails = (id) => {
    return api.get(`/cargo-transfer/api/cargo-transfers/situations/${id}`, {
        normalizer: DefaultNormalizer
    });
};

export const getSituationDetailsNaturSheet = (id) => {
    return api.get(
        `/cargo-transfer/api/cargo-transfers/situations/${id}/natur-sheet`,
        {
            normalizer: DefaultNormalizer
        }
    );
};

export const getSituationWagons = (id) => {
    return api.get(
        `/cargo-transfer/api/cargo-transfers/situations/${id}/wagons`,
        {
            normalizer: DefaultNormalizer
        }
    );
};

export const saveSituationWagons = (data) => {
    return api.patch(
        `/cargo-transfer/api/cargo-transfers/situations/${data.id}`,
        data
    );
};

export const acceptSituationWagons = (data) => {
    return api.post(
        `/cargo-transfer/api/cargo-transfers/situations/${data.id}/accept`,
        data
    );
};

export const detachSituationWagons = ({ situationId, wagonId }) => {
    return api.post(
        `/cargo-transfer/api/cargo-transfers/situations/${situationId}/wagons/${wagonId}/detach`
    );
};

export const getWagonDefectByCode = (params) => {
    return api.get(`${DATA_REQUEST_URL}/wagon_defects_ui`, {
        params,
        normalizer: DataRequestDetailsNormalizer
    });
};

export const exportSituations = async (id) => {
    try {
        const response = await api.get(
            '/qorgau-data/api/data-request/report/invoice/invoice',
            {
                params: {
                    situation_id_eq: id
                },
                responseType: 'blob'
            }
        );
        return response;
    } catch (error) {
        console.error('Error exporting situations:', error);
        throw error;
    }
};

export const deleteSituationFile = ({ fileId, situationId }) => {
    debugger;
    return api.delete(
        `/cargo-transfer/api/cargo-transfers/situations/${situationId}/photos/${fileId}`
    );
};

export const downloadSituationFile = ({ fileId, situationId }) => {
    return api.get(
        `/cargo-transfer/api/cargo-transfers/situations/${situationId}/photos/${fileId}`,
        {
            responseType: 'blob'
        }
    );
};

export const downloadMedia = (id) => {
    return api.get(
        `/cargo-transfer/api/cargo-transfers/situations/${id}/photos`,
        {
            responseType: 'blob'
        }
    );
};
