import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useQueryParam, withDefault } from 'use-query-params';

import { CustomFilterParam } from 'core/CustomFilterParam';

export const useDefectsFilterParams = () => {
    const userStationCode = useSelector(
        (state) => state.auth.user?.profileDataMap?.STATION_CODE
    );

    const filterParam = useMemo(() => {
        return withDefault(CustomFilterParam, {
            all_station_code_in: userStationCode
        });
    }, [userStationCode]);

    return useQueryParam('customFilters', filterParam);
};
