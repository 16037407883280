import { useMutation } from '@tanstack/react-query';
import { noop } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Table } from 'sam-ui-kit';
import { showModal } from 'src/core/modal';
import { formatDate } from 'src/utils/dateUtils';

import { useDataRequest } from 'core/queries/useDataRequest';

import { useCombineFilters } from 'hooks/useCombineFilters';
import { usePagination } from 'hooks/usePagination';
import { useSort } from 'hooks/useSort';

import { TableRowEditControls } from 'components/TableRowEditControls/TableRowEditControls';
import { TextLink } from 'components/TextLink/TextLink';

import {
    AWAITING_ACCEPTANCE,
    DEFAULT_APPLICATION_TABLE_PAGE_SIZE,
    applicationsInitialSort
} from 'modules/Applications/consts';
import {
    deleteApplication,
    getCargosListPaged,
    updateApplication
} from 'modules/Applications/services/services';

import { ApplicationStatus } from '../ApplicationStatus/ApplicationStatus';
import { ApplicationsEditModal } from '../ApplicationsEditModal/ApplicationsEditModal';
import styles from './ApplicationTable.module.scss';

export const ApplicationTable = ({ customFilters }) => {
    const combinedFilters = useCombineFilters({
        filters: {
            sort: useSort(applicationsInitialSort),
            pagination: usePagination(
                { pageIndex: 1, pageSize: DEFAULT_APPLICATION_TABLE_PAGE_SIZE },
                true
            )
        },
        listeners: { customFilters },
        resetPagination: { onListenersChange: true }
    });

    const {
        isFetching,
        data = {},
        refetch
    } = useDataRequest(
        'getCargosList',
        getCargosListPaged,
        combinedFilters.filters
    );

    const { t } = useTranslation();

    const handleEdit = (id) => {
        showModal(ApplicationsEditModal, {
            id: id,
            onCreate: updateApplication
        }).then(refetch, noop);
    };

    const deleteMutation = useMutation({
        mutationFn: deleteApplication,
        onSuccess: refetch
    });

    const handleDelete = (id) => {
        showModal('confirm-delete').then(() => {
            deleteMutation.mutate(id);
        }, noop);
    };

    return (
        <div className={styles.wrapper}>
            <Table
                loading={isFetching}
                data={data.content || []}
                columns={getColumnsConfig(t, handleEdit, handleDelete)}
                sorting={combinedFilters.filters.sort}
                pageSize={DEFAULT_APPLICATION_TABLE_PAGE_SIZE}
                pageCount={data?.totalPages}
                pagination={combinedFilters.filters.pagination}
                onPaginationChange={combinedFilters.setPagination}
                onSortingChange={combinedFilters.setSort}
            />
        </div>
    );
};

const getColumnsConfig = (t, onEdit, onDelete) => {
    return [
        {
            header: '№',
            accessorKey: 'number',
            cell: ({ row }) => (
                <div className={styles.link}>
                    <TextLink
                        label={row.original.id}
                        url={`reception/${row.original.id}`}
                    />
                </div>
            ),
            minSize: 60,
            size: 60
        },
        {
            header: t('applications:application_date'),
            accessorKey: 'dt',
            cell: ({ row }) => (
                <span className={styles.cell}>
                    {formatDate(row.original.dt)}
                </span>
            ),
            enableSorting: true
        },
        {
            header: t('applications:acceptance_start'),
            accessorKey: 'acceptedAt',
            cell: ({ row }) => (
                <span className={styles.cell}>
                    {formatDate(row.original.acceptedAt)}
                </span>
            ),
            enableSorting: true
        },
        {
            header: t('applications:acceptance_end'),
            accessorKey: 'finishedAt',
            cell: ({ row }) => (
                <span className={styles.cell}>
                    {formatDate(row.original.finishedAt)}
                </span>
            ),
            enableSorting: true
        },
        {
            header: t('station'),
            cell: ({ row }) => (
                <span className={styles.cell}>
                    {row.original.acceptanceStation?.name}
                </span>
            )
        },
        {
            header: t('cargo'),
            cell: ({ row }) => (
                <span className={styles.cell}>
                    {row.original.cargoType?.nameRu}
                </span>
            )
        },
        {
            header: t('applications:applicant'),
            accessorKey: 'applicant',
            cell: ({ row }) => (
                <span className={styles.cell}>{row.original.applicant}</span>
            ),
            enableSorting: true
        },
        {
            header: t('applications:security'),
            accessorKey: 'guardFullname',
            cell: ({ row }) => (
                <span className={styles.cell}>
                    {row.original.guardFullname}
                </span>
            ),
            enableSorting: true
        },
        {
            header: t('applications:amount'),
            accessorKey: 'wagonCount',
            cell: ({ row }) => (
                <span className={styles.cell}>{row.original.wagonCount}</span>
            ),
            enableSorting: true
        },
        {
            header: t('status'),
            accessorKey: 'status',
            cell: ({ row }) => (
                <ApplicationStatus status={row.original.status} />
            ),
            enableSorting: true
        },
        {
            header: '',
            id: 'actions',
            cell: ({ row }) => {
                if (row.original.status === AWAITING_ACCEPTANCE) {
                    return (
                        <TableRowEditControls
                            onEdit={() => onEdit(row.original.id)}
                            onDelete={() => onDelete(row.original.id)}
                        />
                    );
                }
                return null;
            },
            size: 75,
            minSize: 75
        }
    ];
};
