const { debounce } = require('lodash');
const { useRef, useEffect, useMemo } = require('react');

export const useDebounce = (callback, delay = 1000) => {
    const ref = useRef();

    useEffect(() => {
        ref.current = callback;
    }, [callback]);

    const debouncedCallback = useMemo(() => {
        const func = (...args) => {
            ref.current?.(...args);
        };

        return debounce(func, delay);
    }, [delay]);

    return debouncedCallback;
};
