import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useQueryParam, withDefault } from 'use-query-params';

import { CustomFilterParam } from 'core/CustomFilterParam';

export const useApplicationFilterParams = () => {
    const userStationCode = useSelector(
        (state) => state.auth.user?.profileDataMap?.STATION_CODE
    );

    const filterParam = useMemo(() => {
        return withDefault(CustomFilterParam, {
            appearance_station_code_eq: userStationCode
        });
    }, [userStationCode]);

    return useQueryParam('customFilters', filterParam);
};
