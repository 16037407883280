export const ACCEPTED_WITHOUT_DEFECT = 'ACCEPTED_WITHOUT_DEFECT';
export const ACCEPTED_WITH_GFA = 'ACCEPTED_WITH_GFA';
export const ACCEPTED_WITH_DEFECT = 'ACCEPTED_WITH_DEFECT';
export const REJECTED = 'REJECTED';
export const AWAITING_ACCEPTANCE = 'AWAITING_ACCEPTANCE';
export const ACCEPTED = 'ACCEPTED';
export const DETACHED = 'DETACHED';
export const NOT_ACCEPTED = "NOT_ACCEPTED"

export const DEFAULT_SEAL = {
    location: null,
    mainSeal: null,
    sealNumber: null,
    sealType: null,
    sealSide: null,
    sealColor: null,
    remark: null,
    type: 'SEAL'
};

export const DEFAULT_TWIST = {
    location: null,
    isProductionTwist: null,
    coilsNumber: null,
    twistSide: null,
    twistColor: null,
    remark: null,
    type: 'TWISTS'
};

export const SEAL_TYPE = 'SEAL';
export const TWIST_TYPE = 'TWISTS';
export const LEFT_DIRECTION = 'LEFT';
export const RIGHT_DIRECTION = 'RIGHT';

export const applicationsInitialSort = [
    { id: 'acceptedAt', desc: true },
    { id: 'dt', desc: true }
];

export const DEFAULT_APPLICATION_TABLE_PAGE_SIZE = 20;