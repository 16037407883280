import { useState } from 'react';

import { HistoryHeader } from '../components/HistoryHeader/HistoryHeader';
import { HistoryTable } from '../components/HistoryTable/HistoryTable';

export const HistoryPage = () => {
    const [isLoading, setIsLoading] = useState(false);

    return (
        <>
            <HistoryHeader isLoading={isLoading} />
            <HistoryTable setIsLoading={setIsLoading} />
        </>
    );
};
