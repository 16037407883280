import React from 'react';
import ReactDOM from 'react-dom/client';
import 'sam-ui-kit/dist/qorgau.css';

import { store } from 'core/store';

import App from './App';
import './i18n';
import './index.scss';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const root = ReactDOM.createRoot(document.getElementById('root'));

// в index.html есть инлайн стили для отображения анимации загрузки и
// скрипты для загрузки шрифтов и отправления запроса на проверку токена
// в переменной window.appLoader записываются следующие переменные:
//    fontsToLoad - кол-во шрифтов оставшихся загрузить
//    tokenStatus - состояние запроса на рефреш токена: PENDING/ALIVE/INVALID
//    loadTime - время с момента запуска запросов из index.html
//    intervalId - id интервала который отсчитывает loadTime

// для избежания мерцания анимации загрузчика
// когда появляется загрузчик и моментально исчезает
const MIN_LOAD_TIME = 1;

// максимальное время для отработки всех запросов перед запуском приложения:
//  - загрузка шрифтов
//  - обновление токена
const MAX_LOAD_TIME = 10;

if (
    window.appLoader.fontsToLoad === 0 &&
    window.appLoader.loadTime > MIN_LOAD_TIME &&
    window.appLoader.tokenStatus !== 'PENDING'
) {
    if (window.appLoader.tokenStatus !== 'ALIVE') {
        store.dispatch({
            type: 'auth/setIsLoggedIn',
            payload: false
        });
    }
    root.render(
        <React.StrictMode>
            <App />
        </React.StrictMode>
    );
    clearInterval(window.appLoader.intervalId);
} else {
    let intervalId = setInterval(() => {
        console.log(window.appLoader.tokenStatus);
        if (
            (window.appLoader.tokenStatus !== 'PENDING' &&
                window.appLoader.fontsToLoad === 0 &&
                window.appLoader.loadTime > MIN_LOAD_TIME) ||
            window.appLoader.loadTime > MAX_LOAD_TIME
        ) {
            if (window.appLoader.tokenStatus !== 'ALIVE') {
                store.dispatch({
                    type: 'auth/setIsLoggedIn',
                    payload: false
                });
            }
            clearInterval(intervalId);
            clearInterval(window.appLoader.intervalId);
            root.render(
                <React.StrictMode>
                    <App />
                </React.StrictMode>
            );
        }
    }, 50);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
