import { useTranslation } from 'react-i18next';
import { Spin } from 'sam-ui-kit';
import { formatDate } from 'src/utils/dateUtils';

import { useDataRequest } from 'core/queries/useDataRequest';

import { useSort } from 'hooks/useSort';

import { Card } from 'components/Card/Card';
import { CardValue } from 'components/CardValue/CardValue';

import { applicationsInitialSort } from 'modules/Applications/consts';
import { getCargosListNonPaged } from 'modules/Applications/services/services';

import { ApplicationStatus } from '../ApplicationStatus/ApplicationStatus';
import styles from './ApplicationCardsList.module.scss';

export const ApplicationCardsList = ({ customFilters }) => {
    const [sort] = useSort(applicationsInitialSort);

    const { isFetching, data = [] } = useDataRequest(
        'getCargosList',
        getCargosListNonPaged,
        {
            sort,
            customFilters
        }
    );

    const { t } = useTranslation();
    return (
        <Spin spinning={isFetching} text={t('loading')} fillOut={true}>
            <div className={styles.wrapper}>
                {data.map((item) => (
                    <Card
                        key={item.id}
                        title={item.id}
                        status={<ApplicationStatus status={item.status} />}
                        hideActions={true}
                        path={`reception/${item.id}`}
                    >
                        <CardValue
                            label={t('applications:application_date')}
                            value={formatDate(item.dt)}
                        />
                        <CardValue
                            label={t('applications:acceptance_start')}
                            value={formatDate(item.acceptedAt)}
                        />
                        <CardValue
                            label={t('applications:acceptance_end')}
                            value={formatDate(item.finishedAt)}
                        />
                        <CardValue
                            label={t('station')}
                            value={item.acceptanceStation?.name || ''}
                        />
                        <CardValue
                            label={t('cargo')}
                            value={item.cargoType?.nameRu || ''}
                        />

                        <CardValue
                            label={t('applications:applicant')}
                            value={item.applicant}
                        />
                        <CardValue
                            label={t('applications:security')}
                            value={item.guardFullname}
                        />
                        <CardValue
                            label={t('applications:amount')}
                            value={item.wagonCount}
                        />
                    </Card>
                ))}
            </div>
        </Spin>
    );
};
