import { isEqual } from 'lodash';
import { useEffect, useRef, useState } from 'react';

const filterStubs = [undefined, undefined];

/**
 * Собирает, хранит и синхронизирует все фильтры в едином стейте.
 */
export const useCombineFilters = ({
    filters = {},
    listeners = {},
    resetPagination = {}
}) => {
    const { customFilters } = listeners;
    const [pagination, setPagination] = filters.pagination || filterStubs;
    const [sort, setSort] = filters.sort || filterStubs;

    const { onListenersChange, onSortChange } = resetPagination;
    const [combinedFilters, setCombinedFilters] = useState({
        pagination,
        customFilters,
        sort
    });

    const combinedFiltersRef = useRef(combinedFilters);

    useEffect(() => {
        combinedFiltersRef.current = combinedFilters;
    }, [combinedFilters]);

    useEffect(() => {
        const resetPage =
            !isEqual(customFilters, combinedFiltersRef.current.customFilters) &&
            onListenersChange;
        if (resetPage) {
            setPagination((old) => ({
                pageIndex: 1,
                pageSize: old.pageSize
            }));
        }

        setCombinedFilters((old) => {
            return {
                sort: old.sort,
                customFilters,
                pagination: {
                    pageIndex: resetPage ? 1 : old.pagination.pageIndex,
                    pageSize: old.pagination.pageSize
                }
            };
        });
    }, [customFilters, setPagination, onListenersChange]);

    useEffect(() => {
        const resetPage =
            !isEqual(sort, combinedFiltersRef.current.sort) && onSortChange;
        if (resetPage) {
            setPagination((old) => ({
                pageIndex: 1,
                pageSize: old.pageSize
            }));
        }
        setCombinedFilters((old) => ({
            sort,
            customFilters: old.customFilters,
            pagination: {
                pageIndex: resetPage ? 1 : old.pagination.pageIndex,
                pageSize: old.pagination.pageSize
            }
        }));
    }, [sort, setPagination, onSortChange]);

    useEffect(() => {
        if (pagination) {
            setCombinedFilters((old) => ({
                ...old,
                pagination
            }));
        }
    }, [pagination, setPagination]);

    useEffect(() => {
        if (sort) {
            setCombinedFilters((old) => ({ ...old, sort }));
        }
    }, [sort, setSort]);

    return {
        filters: combinedFilters,
        setPagination,
        setSort
    };
};
