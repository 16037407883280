import { useScreenSize } from 'hooks/useScreenSize';

import { useApplicationFilterParams } from 'modules/Applications/hooks/useApplicationFilterParams';

import { ApplicationCardsList } from '../components/ApplicationCardsList/ApplicationCardsList';
import { ApplicationsHeader } from '../components/ApplicationHeader/ApplicationHeader';
import { ApplicationTable } from '../components/ApplicationTable/ApplicationTable';

export const ApplicationPage = () => {
    const { isMobile } = useScreenSize();

    const [customFilters] = useApplicationFilterParams();

    return (
        <div>
            <ApplicationsHeader />
            {isMobile ? (
                <ApplicationCardsList customFilters={customFilters} />
            ) : (
                <ApplicationTable customFilters={customFilters} />
            )}
        </div>
    );
};
