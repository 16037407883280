import { useMutation } from '@tanstack/react-query';
import {
    ArrowLeftCircle,
    Check,
    Download,
    EllipsisVertical
} from 'lucide-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button, Dropdown } from 'sam-ui-kit';

import { useScreenSize } from 'hooks/useScreenSize';

import { downloadFile } from 'utils/fileUtils';

import { NOT_ACCEPTED } from 'modules/Applications/consts';
import { exportSituations } from 'modules/Situations/services';
import { getSituationHeaderOptions } from 'modules/Situations/utils';

import styles from './SituationDetailsPageHeader.module.scss';

export const SituationDetailsPageHeader = ({
    data,
    onAcceptAll,
    isLoading
}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleBack = () => {
        navigate('/');
    };
    const { isMobile } = useScreenSize();

    const mutation = useMutation({
        mutationFn: () => {
            return exportSituations(data.id);
        },
        onSuccess: (response) => {
            downloadFile(response, `${t('situation')} №${data.id}`);
        }
    });

    const handleSelect = (e) => {
        switch (e) {
            case 'accept':
                onAcceptAll();
                break;
            case 'download':
                mutation.mutate();
                break;
            default:
                break;
        }
    };

    return (
        <div className={styles.header}>
            <h1 className={styles.pageTitle}>
                <ArrowLeftCircle
                    color="#888888"
                    size={26}
                    onClick={handleBack}
                    cursor="pointer"
                />
                {t('train')} №{data.nomPoezd || ''}
            </h1>
            {isMobile ? (
                <Dropdown
                    renderElement={() => (
                        <EllipsisVertical
                            size={16}
                            cursor="pointer"
                            color="#000"
                        />
                    )}
                    listOffset={20}
                    options={getSituationHeaderOptions(data.status, t)}
                    onChange={handleSelect}
                />
            ) : (
                <div className={styles.actions}>
                    {data.status === NOT_ACCEPTED && (
                        <Button
                            label={t('applications:accept_all_wagons')}
                            variant="primary"
                            onClick={onAcceptAll}
                            icon={<Check size={16} />}
                            loading={isLoading}
                        />
                    )}
                    <Button
                        label={t('actions.download')}
                        onClick={mutation.mutate}
                        variant="default"
                        loading={mutation.isPending}
                        icon={<Download size={16} />}
                    />
                </div>
            )}
        </div>
    );
};
