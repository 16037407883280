import { useCallback, useEffect, useRef, useState } from 'react';
import { DEFAULT_PAGINATION_SIZE } from 'settings';
import { NumberParam, useQueryParam, withDefault } from 'use-query-params';

/**
 *
 * @param initialValues {{pageIndex: number, pageSize: number}}
 * @param updateQueryParams {boolean}
 */
export const usePagination = (
    initialValues = {
        pageIndex: 1,
        pageSize: DEFAULT_PAGINATION_SIZE
    },
    updateQueryParams = true,
    queryPrefix
) => {
    const [pageQueryParam, setPageQueryParam] = useQueryParam(
        queryPrefix ? `${queryPrefix}Page` : 'page',
        withDefault(NumberParam, initialValues.pageIndex)
    );

    const [sizeQueryParam, setSizeQueryParam] = useQueryParam(
        queryPrefix ? `${queryPrefix}Size` : 'size',
        withDefault(NumberParam, initialValues.pageSize)
    );

    const [pagination, setInnerPagination] = useState({
        pageIndex: pageQueryParam || initialValues.pageIndex,
        pageSize: sizeQueryParam || initialValues.pageSize
    });

    const pag = useRef(pagination);

    useEffect(() => {
        pag.current = pagination;
    }, [pagination]);

    const setPagination = useCallback(
        (getNextState) => {
            const nextState = getNextState(pag.current);
            setInnerPagination(nextState);
            if (updateQueryParams) {
                setPageQueryParam(nextState.pageIndex, 'replaceIn');
                setSizeQueryParam(nextState.pageSize, 'replaceIn');
            }
        },
        [setPageQueryParam, setSizeQueryParam, updateQueryParams]
    );

    return [pagination, setPagination];
};
