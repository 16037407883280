import { SituationDetailsPage } from 'modules/Situations/pages/SituationDetailsPage/SituationDetailsPage';
import { WagonDetailsPage } from 'modules/Situations/pages/WagonDetailsPage';
import { SituationsDashboardPage } from './pages/SituationDashboardPage/SituationsDashboardPage';

export const routes = [
    {
        path: '/',
        element: <SituationsDashboardPage />
    },
    {
        path: '/situations/:id',
        element: <SituationDetailsPage />
    },
    {
        path: '/situations/:id/wagon/:wagonId',
        element: <WagonDetailsPage />
    }
];
