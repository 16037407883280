export const INVALID_TOKEN_STATUS = 'INVALID_TOKEN';

export const SUCCESS_STATUS = 'SUCCESS';

export const AUTH_API_URL = '/qorgau-security/api/authenticate';

export const DEFAULT_DATETIME_MASK = 'dd.MM.yyyy HH:mm:ss';
export const DATE_MASK = 'dd.MM.yyyy HH:mm';

export const ENTITLEMENT_URL = '/qorgau-security/api/entlmnt';

export const DDM_DOMAINSETS_URL = '/qorgau-mdm/api/v1/domainsets';

export const DATA_REQUEST_URL = '/qorgau-mdm/api/data-request';
export const DATA_REQUEST_DATA_URL = '/qorgau-data/api/data-request';
export const DATA_REQUEST_EMM_URL = '/emm-data/api/data-request';

export const FILE_URL = '/file/api/file';
export const SECTION_URL = '/loco-mgmt/api/section';

export const EMM_URL = '/emm-web/api/emm';

export const WORKERS_URL = `/emm-driver/api`;

export const DEFAULT_PAGINATION_SIZE = 10;

export const DATA_REQUEST = 'DATA_REQUEST';
export const DDM = 'DDM';
export const ENTLMNT = 'ENTLMNT';

export const USER_HAS_TO_CHANGE_PASSWORD_STATUS = 'USER_HAS_TO_CHANGE_PASSWORD';

//ISO 8601
export const SYSTEM_DATE_FORMAT = "yyyy-MM-dd'T'HH:mm:ss";
export const DISPLAY_DATE_FORMAT = 'dd.MM.yyyy HH:mm:ss';

export const LARGE_BREAKPOINT = 1440;
export const MIDDLE_BREAKPOINT = 769;

export const TELEGRAM_BOT_URL = 'https://t.me/korgau_app_bot';
