import { ChevronLeft, ChevronRight, Download, Trash2, X } from 'lucide-react';
import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';

import { Image } from 'components/Image/Image';

import styles from './PhotoGallery.module.scss';

export const PhotoGallery = ({ fileIds, onClose, onDownload, onDelete }) => {
    const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0);
    const { t } = useTranslation();

    useEffect(() => {
        if (fileIds.length - 1 < currentPhotoIndex) {
            setCurrentPhotoIndex(fileIds.length - 1);
        }
    }, [fileIds, currentPhotoIndex]);

    const handleNextClick = () => {
        if (currentPhotoIndex < fileIds.length) {
            setCurrentPhotoIndex(currentPhotoIndex + 1);
        }
    };

    const handlePrevClick = () => {
        if (currentPhotoIndex > 0) {
            setCurrentPhotoIndex(currentPhotoIndex - 1);
        }
    };

    const handleDelete = () => {
        onDelete(fileIds[currentPhotoIndex]);
    };

    return ReactDOM.createPortal(
        <div className={styles.photoGallery}>
            <div className={styles.close} onClick={onClose}>
                <X size={24} color="#FFFFFF" />
            </div>
            {currentPhotoIndex > 0 && (
                <div className={styles.arrowLeft} onClick={handlePrevClick}>
                    <ChevronLeft size={36} color="#FFFFFF" />
                </div>
            )}
            {currentPhotoIndex < fileIds.length - 1 && (
                <div className={styles.arrowRight} onClick={handleNextClick}>
                    <ChevronRight size={36} color="#FFFFFF" />
                </div>
            )}
            <div className={styles.photo}>
                <Image id={fileIds[currentPhotoIndex]} className={styles.img} />
            </div>
            <div className={styles.panel}>
                <div>
                    {t('photo_from_to', {
                        from: currentPhotoIndex + 1,
                        to: fileIds.length
                    })}
                </div>
                <div className={styles.controls}>
                    {onDownload && (
                        <Download
                            size={20}
                            color="#FFFFFF"
                            onClick={() =>
                                onDownload(fileIds[currentPhotoIndex])
                            }
                            cursor="pointer"
                        />
                    )}
                    {onDelete && (
                        <Trash2
                            size={20}
                            color="#C85858"
                            onClick={handleDelete}
                            cursor="pointer"
                        />
                    )}
                </div>
            </div>
        </div>,
        document.getElementById('gallery-root')
    );
};
