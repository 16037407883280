import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { clsx } from 'clsx';
import { Upload } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { notifications } from 'sam-ui-kit';
import { TELEGRAM_BOT_URL } from 'settings';

import { downloadFile } from 'utils/fileUtils';
import { notificateErrorResponse } from 'utils/serverErrors';

import { PhotoGallery } from 'components/PhotoGallery/PhotoGallery';

import {
    deleteSituationFile,
    downloadMedia,
    downloadSituationFile,
    getSituationDetails
} from 'modules/Situations/services';

import styles from './SituationMedia.module.scss';

export const SituationMedia = ({ situationId }) => {
    const [isGalleryOpen, setIsGalleryOpen] = useState(false);
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    const { data } = useQuery({
        queryFn: () => getSituationDetails(situationId),
        queryKey: ['getSituationDetails', situationId],
        initialData: {
            id: situationId,
            fileIds: []
        }
    });

    const hasMedia = data.fileIds.length > 0;

    useEffect(() => {
        if (isGalleryOpen && hasMedia === false) {
            setIsGalleryOpen(false);
        }
    }, [isGalleryOpen, hasMedia]);

    const downloadAll = useMutation({
        mutationFn: downloadMedia,
        onSuccess: (response) => {
            downloadFile(response, `situation_media_${situationId}`);
        },
        onError: (response) => notificateErrorResponse(response, t)
    });

    const downloadFileMutation = useMutation({
        mutationFn: downloadSituationFile,
        onSuccess: (response) => {
            downloadFile(response);
        },
        onError: (response) => notificateErrorResponse(response, t)
    });

    const deleteFileMutation = useMutation({
        mutationFn: deleteSituationFile,
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: ['getSituationDetails']
            });
            notifications.push({
                type: 'success',
                title: t('file_deleted')
            });
        },
        onError: (response) => notificateErrorResponse(response, t)
    });

    const handleDownloadAll = () => {
        downloadAll.mutate(situationId);
    };

    const handleDownload = (fileId) => {
        downloadFileMutation.mutate({ fileId, situationId });
    };

    const handleDelete = (fileId) => {
        deleteFileMutation.mutate({ fileId, situationId });
    };

    return (
        <div className={styles.stationMedia}>
            <div>
                <span
                    className={hasMedia && 'cursor-pointer'}
                    onClick={() => setIsGalleryOpen(true)}
                >
                    <span className={styles.countLabel}>Медиа</span>
                    <span className={styles.count}>
                        ({data.fileIds.length})
                    </span>
                </span>
            </div>

            {hasMedia && (
                <div className={styles.download}>
                    <span className={styles.link} onClick={handleDownloadAll}>
                        {t('download_all')}
                    </span>
                </div>
            )}

            {/*<div className={styles.row}>gallery</div>*/}

            <div className={clsx(styles.row, styles.upload)}>
                <Upload size={16} color="#4079E4" />
                <Link
                    className={styles.link}
                    target="_blank"
                    to={`${TELEGRAM_BOT_URL}?start=${situationId}`}
                >
                    {t('select_file')}
                </Link>
            </div>

            {isGalleryOpen && (
                <PhotoGallery
                    fileIds={data.fileIds}
                    onClose={() => setIsGalleryOpen(false)}
                    onDelete={handleDelete}
                    onDownload={handleDownload}
                />
            )}
        </div>
    );
};
