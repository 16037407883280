import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from 'sam-ui-kit';
import { formatDate } from 'src/utils/dateUtils';
import { useQueryParam, withDefault } from 'use-query-params';

import { CustomFilterParam } from 'core/CustomFilterParam';
import { useDataRequest } from 'core/queries/useDataRequest';

import { useCombineFilters } from 'hooks/useCombineFilters';
import { usePagination } from 'hooks/usePagination';
import { useSort } from 'hooks/useSort';

import { TextLink } from 'components/TextLink/TextLink';

import { HistoryStatus } from 'modules/History/components/HistoryStatus';
import { HISTORY_PAGE_SIZE, historyInitialSort } from 'modules/History/consts';
import { getHistoryPagedApps } from 'modules/History/services';

import styles from './HistoryTable.module.scss';

const filterParam = withDefault(CustomFilterParam, {});

export const HistoryTable = ({ setIsLoading }) => {
    const [customFilters] = useQueryParam('customFilters', filterParam);

    const combinedFilters = useCombineFilters({
        filters: {
            sort: useSort(historyInitialSort),
            pagination: usePagination(
                { pageIndex: 1, pageSize: HISTORY_PAGE_SIZE },
                true
            )
        },
        listeners: { customFilters },
        resetPagination: { onListenersChange: true }
    });

    const { isFetching, data = {} } = useDataRequest(
        'historyApps',
        getHistoryPagedApps,
        combinedFilters.filters
    );

    useEffect(() => {
        setIsLoading(isFetching);
    }, [isFetching, setIsLoading]);

    const { t } = useTranslation();
    return (
        <div className={styles.table}>
            <Table
                loading={isFetching}
                data={data.content || []}
                columns={getColumnsConfig(t)}
                pageSize={HISTORY_PAGE_SIZE}
                sorting={combinedFilters.filters.sort}
                onSortingChange={combinedFilters.setSort}
                pageCount={data?.totalPages}
                pagination={combinedFilters.filters.pagination}
                onPaginationChange={combinedFilters.setPagination}
            />
        </div>
    );
};

const getColumnsConfig = (t) => {
    return [
        {
            header: '№',
            accessorKey: 'appId',
            cell: ({ row }) => (
                <div className={styles.link}>
                    <TextLink
                        label={row.original.appId}
                        url={`/history/${row.original.appId}/carriages/${row.original.appWagonId}`}
                    />
                </div>
            ),
            enableSorting: true
        },
        {
            header: t('carriage'),
            accessorKey: 'wagonCode',
            enableSorting: true
        },
        {
            header: t('history:container'),
            accessorKey: 'containerCode',
            enableSorting: true
        },
        {
            header: t('history:application_date'),
            accessorKey: 'dt',
            cell: ({ row }) => formatDate(row.original.dt),
            enableSorting: true,
            size: 200
        },
        {
            header: t('from'),
            cell: ({ row }) => row.original.acceptanceStation?.name
        },
        {
            header: t('to'),
            cell: ({ row }) => row.original.stanNazn?.name
        },
        {
            header: t('history:detached_date'),
            accessorKey: 'detachedAt',
            cell: ({ row }) => formatDate(row.original.detachedAt),
            enableSorting: true,
            size: 200
        },
        {
            header: t('cargo'),
            cell: ({ row }) => row.original.cargoType?.nameRu
        },
        {
            header: t('history:defect'),
            cell: ({ row }) => row.original.defect?.nameRu,
            size: 150
        },
        {
            header: t('history:remark'),
            cell: ({ row }) => row.original.remark,
            size: 200
        },
        {
            header: t('status'),
            accessorKey: 'initialStatus',
            cell: ({ row }) => (
                <HistoryStatus status={row.original.initialStatus} />
            ),
            enableSorting: true,
            size: 220
        }
    ];
};
